<template>
  <v-footer>
    <v-card
      elevation="0"
      rounded="0"
      width="100%"
      class="bg-primary-darken-1 text-center"
    >
      <v-card-text>
        <v-btn
          class="mx-4"
          icon="fas fa-comment"
          variant="plain"
          color="white"
          href="mailto:deisonandresv@gmail.com"
        ></v-btn>
        <v-btn
          class="mx-4"
          variant="plain"
          color="white"
          icon="fas fa-university"
          href="http://unad.edu.co/"
        ></v-btn>
        <v-btn
          class="mx-4"
          variant="plain"
          color="white"
          icon="fab fa-github-square"
          href="https://github.com/Deyson19"
        >
        </v-btn>
        <v-btn
          class="mx-5"
          variant="plain"
          color="white"
          icon="fab fa-youtube"
          href="https://www.youtube.com/channel/UC3pc9SwxmLhtc--e_O1Psmg"
        >
        </v-btn>
        <v-btn
          class="mx-4"
          variant="plain"
          color="white"
          icon="fab fa-instagram"
          href="https://www.instagram.com/deyson_vente/"
        ></v-btn>
      </v-card-text>

      <v-card-tex>
        &copy; {{ new Date().getFullYear() }} - Portafolio | Developed by Deyson
        Vente
      </v-card-tex>
    </v-card>
    <!-- Histats.com  (div with counter) -->
    <div id="histats_counter"></div>
    <!-- Histats.com  START  (aync)-->
    <script type="text/javascript">
      var _Hasync = _Hasync || [];
      _Hasync.push(["Histats.start", "1,4852087,4,1,120,40,00010101"]);
      _Hasync.push(["Histats.fasi", "1"]);
      _Hasync.push(["Histats.track_hits", ""]);
      (function () {
        var hs = document.createElement("script");
        hs.type = "text/javascript";
        hs.async = true;
        hs.src = "//s10.histats.com/js15_as.js";
        (
          document.getElementsByTagName("head")[0] ||
          document.getElementsByTagName("body")[0]
        ).appendChild(hs);
      })();
    </script>
    <noscript
      ><a href="/" target="_blank"
        ><img
          src="//sstatic1.histats.com/0.gif?4852087&101"
          alt="contadores web"
          border="0" /></a
    ></noscript>
    <!-- Histats.com  END  -->
  </v-footer>
</template>

<script>
export default {};
</script>

<style>
.v-footer {
  padding: 8px 0 !important;
}
</style>