<template>
  <nav class="navbar navbar-expand-sm navbar-dark bg-cyan">
    <div class="container-fluid">
      <v-btn>
        <v-icon color="black" left class="mr-2"> fa-solid fa-code </v-icon>
        Deyson Venté
      </v-btn>

      <v-spacer></v-spacer>
      <v-spacer></v-spacer>
      <button
        class="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#mynavbar"
      >
        <span class="navbar-toggler-icon"></span>
      </button>

      <div class="collapse navbar-collapse" id="mynavbar">
        <form class="d-flex">
          <ul class="navbar-nav me-auto">
            <li class="nav-item">
              <v-btn class="text-yellow" text @click="scroll('home')"
                >Home</v-btn
              >
            </li>
            <li class="nav-item">
              <v-btn text @click="scroll('about')">Conóceme</v-btn>
            </li>
            <li class="nav-item">
              <v-btn text @click="scroll('portafolio')">Portafolio</v-btn>
            </li>
            <li class="nav-item">
              <v-btn text @click="scroll('services')">Proyectos</v-btn>
            </li>
            <li class="nav-item">
              <v-btn text @click="scroll('page')">Experiencia</v-btn>
            </li>
            <li class="nav-item">
              <v-btn text @click="scroll('contact')">Contacto</v-btn>
            </li>
            <li class="nav-item">
              <v-btn text @click="scroll('biog')">Capacitaciones</v-btn>
            </li>
          </ul>
        </form>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  methods: {
    scroll(refName) {
      const element = document.getElementById(refName);
      element.scrollIntoView({ behavior: "smooth" });
    },
  },
};
</script>

<style>
.v-btn {
  background: none;
}
</style>