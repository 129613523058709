<template>
  <v-app id="home">
    <NavBar />
    <v-container fluid>
      <div class="head">
        <div class="container-fluid">
          <v-row>
            <v-col cols="5">
              <div class="container-fluid mt-16" style="position: relative">
                <h3 class="text-blue text-decoration-underline">
                  Hola a todos,
                </h3>
                <h3 class="text-white">Soy {{ nombre }}</h3>
                <span class="text-grey">Tecnólogo en Desarrollo de Software</span><br />
                <v-btn title dark class="text-blue m-1" variant="outlined">
                  <i class="fab fa-linkedin mdi-size-xl" aria-hidden="true"></i>
                  LinkedIn
                </v-btn>
              </div>
            </v-col>
            <v-col cols="2">
              <div style="
                  position: absolute;
                  z-index: 9999;
                  bottom: 0;
                  margin-left: auto;
                  margin-right: auto;
                  left: 0;
                  right: 0;
                  text-align: center;
                ">
                <v-icon>fas fa-angle-double-down</v-icon>
              </div>
            </v-col>
            <v-col cols="14">
              <div style="position: relative; z-index: 9999;" class="mt-12">
                <v-img src="../assets/fotos/image_1.jpg" contain max-height="300">
                </v-img>
              </div>
            </v-col>
          </v-row>
        </div>
      </div>

      <div class="container-fluid">
        <v-col cols="12" class="mt-16" id="about">
          <v-row>
            <v-col cols="12" sm="6">
              <div class="egg2 img-thumbnail">
                <v-img src="../assets/fotos/image_2.jpg" max-height="250"></v-img>
              </div>
            </v-col>
            <v-col cols="12" sm="6">
              <h2 class="text-center">Sobre Mi</h2>
              <div style="width: 120px">
                <v-slider v-model="slider2" color="yellow" class="track-color">
                </v-slider>
              </div>
              <h4 class="mt-n4">Soy un desarrollador Full Stack</h4>
              <p class="text-grey">
                Soy muy apasionado con lo que hago, siempre doy lo mejor de mi
                en cualquier circunstancia en la que me encuentre, busco la
                mejora constante y siempre me apoyo y tomo lo mejor que me
                pueden aportar las personas que más saben del área buscando
                nuevas alternativas y perspectivas de las cosas. Me gusta
                controlar mi tiempo en las actividades que realizo evitando que
                se prolonguen más allá de la planificación.
              </p>
              <div class="card border-info mb-3" style="max-width: 18rem">
                <div class="card-header">
                  <span class="badge rounded-pill bg-success">Aprendiendo actualmente</span>
                </div>
                <div class="card-body">
                  <p class="card-text">
                  <ol class="list-group list-group-numbered">
                    <li class="list-group-item d-flex justify-content-between align-items-start">
                      <div class="ms-2 me-auto">
                        <div class="fw-bold">VueJS</div>
                        JavaScript - Framework
                      </div>
                      <span class="badge bg-primary rounded-pill">1</span>
                    </li>
                    <li class="list-group-item d-flex justify-content-between align-items-start">
                      <div class="ms-2 me-auto">
                        <div class="fw-bold">Blazor</div>
                        C#.Net Framework
                      </div>
                      <span class="badge bg-primary rounded-pill">2</span>
                    </li>
                  </ol>
                  </p>
                </div>
              </div>

              <br />

              <v-btn tile dark color="blue" class="mt-4" href="https://bit.ly/cv-deyson-vente-es">
                Descarcar CV
              </v-btn>
              <v-btn tile dark color="yellow" class="mt-4 ml-4" href="https://bit.ly/cv-deyson-vente-en">
                Download CV
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </div>

      <div class="text-center mt-4">
        <h2>Lo que hago</h2>
        <div style="width: 120px; margin: 0 auto">
          <v-slider v-model="slider2" color="info" class="track-color"></v-slider>
        </div>
        <section class="col m-1">
          <v-btn color="gray" class="mr-2 mt-1">All</v-btn>
          <v-btn color="blue" class="mr-2 mt-1" variant="tonal">Desarrollo Web</v-btn>
          <v-btn color="orange" class="mr-3 mt-1" variant="tonal">Desarrollo Móvil y Escritorio</v-btn>
          <v-btn color="green" class="mr-3 mt-1" variant="tonal">Desarrollo Backend</v-btn>
          <v-btn color="red" class="mr-2" variant="tonal">Testing</v-btn>
        </section>
      </div>
      <div class="container container-fluid border border-dark bg-cyan-lighten-5">
        <v-col class="padd" id="portafolio">
          <div class="first" id="project">
            <div class="row">
              <div class="col">
                <div class="card">
                  <div>
                    <v-btn icon="fas fa-laptop" color="#fbdf7e" class="text-white"></v-btn>
                    <h3 class="ml-3 mt-4">Desarrollador Web</h3>
                    <p class="text-grey ml-3 mt-4 text-sm-subtitle">
                      Tengo la capacidad de desarrollar aplicaciones escalables
                      utilizando estandares internacionales y buenas practicas
                      en el desarrollo.
                    </p>
                  </div>
                </div>
              </div>
              <div class="col">
                <div class="card">
                  <div class="child">
                    <v-btn icon="fas fa-mobile-alt" color="#fbdf7e" class="text-white"></v-btn>
                    <h3 class="ml-3 mt-4">App Developer</h3>
                    <p class="text-grey ml-3 mt-4 text-sm-subtitle">
                      He desarrollado aplicaciones nativas para el sistema
                      android
                      <br />
                    </p>
                  </div>
                </div>
              </div>
              <div class="col">
                <div class="card">
                  <div class="child">
                    <v-btn icon="fas fa-camera" color="#fbdf7e" class="text-white"></v-btn>
                    <h3 class="ml-3 mt-4">Pruebas de Software</h3>
                    <p class="text-grey ml-2 text-sm-subtitle">
                      Capacidad de diseñar, planificar y desarrollar pruebas de
                      software de distintos niveles y alcance en la
                      organización.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <v-row>
              <v-col cols="12"> </v-col>
            </v-row>
          </div>
        </v-col>
      </div>

      <v-col cols="12" class="imgHover">
        <div class="first" id="services">
          <h3 class="card-title text-center text-teal">
            Proyectos más recientes
          </h3>
          <hr />
          <div class="row">
            <div class="col" v-for="(item, i) in items" :key="i">
              <div class="shadow-lg rounded border border-dark m-2">
                <v-card-title>
                  {{ item.text }}
                </v-card-title>
                <img :src="item.img" class="img-thumbnail" width="325" height="236" alt="Proyectos recientes" />
              </div>
            </div>
          </div>
        </div>
      </v-col>

      <v-col cols="12" id="page">
        <!-- tarjetas  experiencia-->
        <div class="container container-fluid first">
          <h3 class="card-title text-center text-teal">
            Experiencia y Conocimientos
          </h3>
          <hr />
          <div class="row">
            <div class="col">
              <div class="card">
                <div>
                  <v-btn icon="fa fa-briefcase" color="grey" class="text-white">
                  </v-btn>
                  <v-card-title>Experiencia</v-card-title>
                  <v-card-subtitle>Junior Developer </v-card-subtitle>
                  <v-card-text>
                    <ul class="list-group list-group-flush">
                      <li class="list-group-item">Desarrollador Full stack</li>
                      <li class="list-group-item">Proyectos Personales</li>
                      <li class="list-group-item">2019-2023</li>
                      <!-- </ul>
                    <hr />
                    <ul class="list-group list-group-flush"> -->
                      <li class="list-group-item">Desarrollador Back-End</li>
                      <li class="list-group-item">Technoconsulting.co</li>
                      <li class="list-group-item">2021 || 2023</li>
                    </ul>
                  </v-card-text>
                </div>
              </div>
            </div>
            <div class="col">
              <div class="card">
                <div>
                  <v-img src="../assets/language.png" height="60px"> </v-img>
                  <v-card-title>Lenguajes</v-card-title>
                  <v-card-subtitle>Experiencia programando en:</v-card-subtitle>
                  <v-card-text>
                    <ul class="list-group list-group-flush" id="elementes">
                      <li class="list-group-item">C#</li>
                      <li class="list-group-item">JAVA</li>
                      <li class="list-group-item">PHP</li>
                      <li class="list-group-item">JavaScript</li>
                      <li class="list-group-item">HTML</li>
                    </ul>
                  </v-card-text>
                </div>
              </div>
            </div>
            <div class="col">
              <div class="card">
                <div>
                  <v-img src="../assets/tecno.png" height="40px"> </v-img>
                  <v-card-title>Tecnologías</v-card-title>
                  <v-card-subtitle>Recursos para desarrollo</v-card-subtitle>
                  <v-card-text>
                    <ul class="list-group list-group-flush">
                      <li class="list-group-item">Git: GitHub | GitLab</li>
                      <li class="list-group-item">SQL/MySQL</li>
                      <li class="list-group-item">ASP.Net Core MVC</li>
                      <li class="list-group-item" style="font-style: italic">
                        Blazor
                      </li>
                      <li class="list-group-item">VSCode</li>
                    </ul>
                  </v-card-text>
                </div>
              </div>
            </div>
          </div>
        </div>
      </v-col>
      <!-- aprendizaje -->
      <v-col cols="12" id="biog">
        <div class="hire">
          <v-row>
            <v-col>
              <div class="card bg-blue-grey-darken-1 text-white">
                <div class="card-body">
                  <h3 class="card-title text-center">Capacitaciones</h3>
                  <p class="text-center">
                    A continuación se presentan las plataformas donde adquiero
                    nuevas habilidades y conocimientos que me han permitido
                    crecer como a nivel personal y profesional.
                  </p>
                  <ul class="list-group list-group-flush list-inline">
                    <li class="list-group-item" v-for="(aprendizaje, ap) in capacitaciones" :key="ap">
                      {{ aprendizaje }}
                    </li>
                  </ul>
                </div>
              </div>
            </v-col>
          </v-row>
        </div>
      </v-col>

      <!-- contact info -->
      <v-col cols="12" sm="12" class="px-16" id="contact">
        <v-row>
          <v-col cols="12" sm="4">
            <div class="child">
              <h3>Información de Contacto</h3>
              <v-btn icon="fas fa-map-marker-alt " class="mt-10" variant="outlined"></v-btn><br />
              <span class="text-caption">Colombia</span><br />
              <v-btn icon="fas fa-phone" variant="outlined"></v-btn><br />
              <span class="text-cap">+57 3137008837</span><br />
              <v-btn icon="fa fa-envelope mt-3 mb-3 ml-auto" variant="outlined"></v-btn><br />
              <span class="text-cap">deisonandresv@gmail.com</span><br />
            </div>
          </v-col>
          <!-- form contact -->
          <v-col cols="12" sm="8">
            <h3 class="mt-8 text-center text-bg-transparent text-dark-darken-4 shadow-sm" style="
                text-transform: capitalize;
                border-color: aqua;
                text-shadow: 2px 2px 3px white;
              ">
              Puedes dejarme un correo
            </h3>
            <!-- </div> -->

            <form class="form-group" v-on:submit.prevent>
              <label class="form-label">Asunto del Correo</label>
              <input type="text" id="campoAsunto" required placeholder="Ingresar Asunto" v-model="correo.Asunto"
                variant="outlined" class="form-control" />
              <!-- <div class="mb-3">
            </div> -->
              <label class="form-label">Email</label>
              <input type="email" id="campoEmail" class="form-control" placeholder="Ingresar Email de Contaco"
                v-model="correo.RemitenteForm" variant="outlined" required />
              <!-- <div class="mb-3"> -->
              <label for="contenido" class="form-label">Mensaje</label>
              <textarea cols="30" rows="10" id="campoMensaje" class="form-control" placeholder="Mensaje"
                v-model="correo.Contenido" variant="outlined" required>
              </textarea>
              <div class="input-group-append m-1">
                <button type="submit" v-on:click="enviarCorreo()" class="btn btn-success btn-lg">
                  Enviar Correo
                </button>
              </div>
            </form>
            <!-- <div class="mb-3">
              
            </div> -->
          </v-col>
        </v-row>
      </v-col>
    </v-container>
    <FooterView />
  </v-app>
</template>

<script>
//#region  Imports componentes
import { defineComponent } from "vue";

// Components
import NavBar from "../components/NavBar.vue";

import FooterView from "../components/FooterView.vue";

import axios from "axios";

import sweetalert2 from "sweetalert2";

//#endregion
export default defineComponent({
  name: "HomeView",

  setup() {
    return {
      slider2: 50,
      items: [
        {
          img: require("@/assets/proyectos/bloodBank_dash.png"),
          text: "BloodBank - Dashboard",
        },
        // {
        //   img: require("@/assets/proyectos/bloodBank_login.png"),
        //   text: "BloodBank Login",
        // },
        {
          img: require("@/assets/proyectos/bloodBank_gestor.png"),
          text: "BloodBank Gestor",
        },
        {
          img: require("@/assets/proyectos/ecommerce_api.png"),
          text: "API Ecommerce",
        },
        {
          img: require("@/assets/proyectos/ecommerce_end_categoria.png"),
          text: "API Ecommerce - Categoria",
        },
        {
          img: require("@/assets/proyectos/ecommerce_end_producto.png"),
          text: "API Ecommerce - Producto",
        },
        {
          img: require("@/assets/proyectos/ecommerce_dash.png"),
          text: "Ecommerce Dashboard",
        },
        {
          img: require("@/assets/proyectos/registrar_imagen.png"),
          text: "Nueva Imagen - Album Empresarial",
        },
        {
          img: require("@/assets/proyectos/visualizar_imagen.png"),
          text: "Consultar Imagen - Album Empresarial",
        },
        {
          img: require("@/assets/proyectos/ingresar_usuario_vehiculos_java.png"),
          text: "Registrar Usuario - Taller de Vehículos",
        },
        {
          img: require("@/assets/proyectos/ingresar_vehiculo_Java.png"),
          text: "Registrar Vehículo - Taller de Vehículos",
        },
      ],
      nombre: "Deyson A. Vente",
      capacitaciones: [
        "Udemy.com",
        "LinkedIn Learning",
        "Google Activate",
        "Aprende.Org",
      ],
      correo: {
        Asunto: "",
        RemitenteForm: "",
        Contenido: "",
      },
      link_cv_en:
        "https://drive.google.com/file/d/1z4FNb5jzEq8OgxzXk8vU78cc9yElZJCy/view?usp=drive_link",
      link_cv_es:
        "https://drive.google.com/file/d/1z4FNb5jzEq8OgxzXk8vU78cc9yElZJCy/view?usp=drive_link",
    };
  },
  methods: {
    enviarCorreo() {
      axios
        .post(
          // "https://localhost:7192/api/EnviarCorreo",
          // "https://envioscorreos.jorgevente.com/api/EnviarCorreo",
          "https://portafolio-deyson20.azurewebsites.net/api/EnviarCorreo",
          this.correo
        )
        .then((response) => {
          //console.log("Se ha enviado el mensaje");
          sweetalert2
            .fire({
              position: "center",
              icon: "success",
              title: response.data.message,
              showConfirmButton: true,
            })
            .then((result) => {
              if (result.isConfirmed) {
                window.location.reload();
                this.loading = false;
              }
            });
        })
        .catch((error) => {
          this.loading = false;
          sweetalert2
            .fire({
              position: "center",
              icon: "warning",
              title:
                "Ha ocurrido un problema al enviar el correo. Intenta con otro medio de contacto.",
              confirmButtonText: "Ok, Cerrar",
              allowOutsideClick: false,
            })
            .then((result) => {
              if (result.isConfirmed) {
                window.location.reload();
                console.error("Mensaje de error: ", error);
              }
            });
        });
    },
  },
  components: {
    NavBar,
    FooterView,
  },
});
</script>

<style scoped>
.v-container {
  padding: 25px 0;
}

.head {
  position: relative;
  text-align: center;
  padding: 12px;
  margin-bottom: 6px;
  height: 400px;
  width: 100%;
  color: white;
}

.head:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 50%;
  height: 100%;
  background: rgb(0, 0, 0);
  transform: skew(0deg, 5deg);
}

.head:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  width: 50%;
  background: black;
  transform: skew(0deg, -5deg);
}

.egg {
  position: relative;
  display: block;
  margin-left: 0;
  margin-top: 50px;
  width: auto;
  height: auto;
  background-color: #146d9671;
  border-radius: 50% 50% 50% 50% / 60% 60% 40% 40%;
}

.first {
  width: 100%;
  height: auto;
  text-align: center;
  padding: 2rem 2rem;
}

.child {
  display: inline-block;
  padding: 2rem 1rem;
  vertical-align: middle;
  text-align: center;
  margin-right: 8px;
  margin: auto;
}

.imgHover {
  padding: 2px;
}

.pre {
  width: 100%;
  height: auto;
  margin: auto;
  text-align: center;
  padding: 0 250px;
  background-color: #eceaea;
}

.hire {
  margin-top: 1%;
}

@media only screen and (max-width: 400px) {
  .hire {
    text-align: center;
  }
}
</style>
